import type { UseScriptTagOptions } from '@vueuse/core'
import type {
  GsiButtonConfiguration,
  IdConfiguration,
  PromptMomentNotification,
} from 'google-one-tap'
import { watch } from 'vue'

interface Params {
  selector: string | Ref
  initializeConfig?: Partial<IdConfiguration>
  scriptTagOptions?: UseScriptTagOptions
  config?: GsiButtonConfiguration
  buttonClickHandler?: () => void
  promptCallback?: (notification: PromptMomentNotification) => void
  force?: boolean
}

interface RenderButtonParams {
  selector: string | Ref
  config?: GsiButtonConfiguration
  clickHandler?: Params['buttonClickHandler']
}

const getGoogleClientId = (initializeConfig: Partial<IdConfiguration>) => {
  return initializeConfig.client_id || useRuntimeConfig().public.googleClientId
}

const initialize = ({
  config,
  force,
}: {
  config: Partial<IdConfiguration>
  force: boolean
}) => {
  const isInitialized = useState('isInitialized', () => false)

  if (isInitialized.value && !force) return
  window.google.accounts.id.initialize({
    client_id: getGoogleClientId(config),
    use_fedcm_for_prompt: false,
    ...config,
  })
  isInitialized.value = true
}

const promptGoogleOneTap = (momentListener?: Params['promptCallback']) => {
  window.google.accounts.id.prompt(momentListener)
}

const renderButton = ({ selector, config, clickHandler }: RenderButtonParams) =>
  tryOnMounted(() => {
    const button =
      typeof selector === 'string'
        ? document.querySelector(selector)
        : selector.value

    if (!button) return
    window.google.accounts.id.renderButton(
      button,
      { locale: 'fr', ...config },
      clickHandler,
    )
  })

export const useGoogleAuth = ({
  initializeConfig = {},
  force = false,
  scriptTagOptions = {},
}: {
  initializeConfig?: Partial<IdConfiguration>
  force?: boolean
  scriptTagOptions?: UseScriptTagOptions
}) => {
  const { authenticatedTemp } = useAuthenticatedTemp()

  const scriptTagState = useState<HTMLScriptElement | null>(
    'scriptTag',
    () => null,
  )

  watch(
    authenticatedTemp,
    (isAuthenticated) => {
      if (isAuthenticated) return

      if (scriptTagState.value) {
        return initialize({ config: initializeConfig, force })
      }

      const { scriptTag } = useScriptTag(
        'https://accounts.google.com/gsi/client',
        () => {
          initialize({ config: initializeConfig, force })
          syncRef(scriptTagState, scriptTag)
        },
        scriptTagOptions,
      )
    },
    { immediate: true },
  )

  return {
    renderButton: (renderButtonParams: RenderButtonParams) =>
      whenever(scriptTagState, () => renderButton(renderButtonParams), {
        immediate: true,
      }),
    promptGoogleOneTap: (momentListener?: Params['promptCallback']) =>
      whenever(scriptTagState, () => promptGoogleOneTap(momentListener), {
        immediate: true,
      }),
  }
}
