<template>
  <div class="main">
    <div
      v-if="loading"
      class="backdrop"
    />
    <div class="u-d-f u-jc-sb u-ai-c">
      Me connecter avec
      <span
        class="link"
        @click="goToRegister"
        >Vous n’avez pas de compte ?</span
      >
    </div>
    <div
      class="hr"
      :class="{ 'is-loading': loading }"
    />
    <div class="o-row google-button-container">
      <div class="google-login-button"></div>
    </div>

    <div class="o-row">
      <div class="o-col u-12">
        <button
          class="btn btn-fb"
          @click="loginWithFacebook"
        >
          <img
            src="~/assets/icons/facebook.svg"
            alt="Facebook icon"
          />
          Facebook
        </button>
      </div>
    </div>
    <hr />
    <form
      autocomplete="off"
      @submit.prevent="submit"
    >
      <div class="o-row u-mb-4">
        <div class="o-col u-12 u-6@tb">
          <div class="c-form__group u-mb-3 u-mb-0@tb">
            <input
              id="username"
              v-model="form.username"
              type="email"
              class="c-form__control"
              name="username"
              autocomplete="username"
              placeholder="Adresse e-mail*"
              autofocus
              required
            />
          </div>
        </div>
        <div class="o-col u-12 u-6@tb">
          <div class="c-form__group u-mb-3 u-mb-0@tb">
            <input
              id="password"
              v-model="form.password"
              type="password"
              class="c-form__control"
              autocomplete="current-password"
              name="password"
              placeholder="Mot de passe*"
              required
            />
          </div>
        </div>
      </div>
      <div
        v-if="error"
        class="is-invalid u-mb-3"
      >
        {{ error }}
      </div>
      <div class="o-row u-ai-c u-jc-sb u-jc-fe@tb">
        <div class="o-col u-auto">
          <span
            class="link-pass"
            @click="openForgottenModal"
          >
            Mot de passe oublié ?
          </span>
        </div>
        <div class="o-col u-auto">
          <ButtonSimple
            type="submit"
            label="Se connecter"
            :disabled="loading"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import type { Response } from '@/types/facebook'
import type { IFetchError } from 'ofetch'
import { useVfm } from 'vue-final-modal'
import type { Endpoints, RewardModalProps } from '~/types'

const emit = defineEmits<{
  openRewardModal: [data: RewardModalProps]
  openForgottenPassword: []
}>()

const managePostLogin = useManagePostLogin(emit)
const auth = useAuth()

const { renderButton } = useGoogleAuth({
  force: true,
  initializeConfig: {
    callback: async (credentialResponse) => {
      await googleAuthCallback(credentialResponse)

      gtag('event', 'login', {
        loginMethod: 'google',
      })

      managePostLogin()
    },
  },
})

renderButton({
  selector: '.google-login-button',
  config: {
    theme: 'outline',
    size: 'large',
    width: 468,
  },
})

const vfm = useVfm()

const loading = ref(false)
const error = ref<string>()
const { gtag } = useGtag()
const form = ref({
  username: '',
  password: '',
})

const submit = async () => {
  loading.value = true
  error.value = undefined

  try {
    await auth.signIn(form.value, {
      redirect: false,
    })

    gtag('event', 'login', {
      loginMethod: 'email',
    })
  } catch (err) {
    error.value = (
      err as IFetchError<{ message?: string; code?: number }>
    )?.data?.message

    loading.value = false
    return
    // OLD TODO manage `Email address not verified.`
    // It can be an user who delete his mail with activation link
    // So he is blocked and cannot finish the last registration step
  }

  managePostLogin()
  loading.value = false
}

const openForgottenModal = () => {
  emit('openForgottenPassword')
}

const route = useRoute()

const goToRegister = () => {
  vfm.closeAll()

  if (route.path !== '/inscription') {
    return navigateTo({
      path: '/inscription',
    })
  }
}

const loginWithFacebook = async () => {
  gtag('event', 'login', {
    loginMethod: 'facebook',
  })
  const { $FB } = useNuxtApp()

  $FB.getLoginStatus((response) => {
    if (response.status === 'connected') {
      // connected
      loginFb(response)
    } else {
      // not_authorized
      $FB.login(
        (response) => {
          if (response.authResponse) {
            return loginFb(response)
          }
          loading.value = false
        },
        { scope: 'email' },
      )
    }
  })
}

const loginFb = async (fbLoginResponse: Response) => {
  loading.value = true

  try {
    const { data, error } = await useCustomFetch<
      Endpoints['GET__CONNECT_FACEBOOK']
    >(API.GET__CONNECT_FACEBOOK, {
      method: 'POST',
      body: {
        token: fbLoginResponse.authResponse.accessToken,
      },
    })

    const rawData = unref(data)
    if (error.value || !rawData) {
      //TODO: check if error.value is correct
      throw new Error(error?.value?.message)
    }

    const { setToken } = useAuthState()
    if (rawData.token) {
      // TODO: pass rawData.refresh_token too when refresh provider is setup
      setToken(rawData.token)
      await auth.getSession({ force: true })
    }
    gtag('event', 'login', {
      loginMethod: 'facebook',
    })

    await managePostLogin()
  } catch (err) {
    error.value = (
      err as IFetchError<{ message?: string; code?: number }>
    )?.data?.message
  } finally {
    loading.value = false
  }
}
</script>

<style lang="scss" scoped>
.main {
  position: relative;
}

.google-button-container {
  display: flex;
  justify-content: center;

  .google-login-button {
    display: flex;
    justify-content: center;
    height: 40px;
    margin-bottom: 1.6rem;
  }
}

hr {
  margin: 1.5rem 0;
  border-bottom: 0;
  opacity: 0.15;
}

.hr {
  position: relative;
  height: 1px;
  margin: 1.5rem 0;
  overflow: hidden;
  background-color: rgb(0 0 0 / 0.15);

  &.is-loading {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: -25%;
      display: block;
      width: 25%;
      height: 100%;
      background-color: $blue;
      animation: loading 0.7s infinite linear;
    }
  }
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 4.6rem;

  &-fb {
    color: #fff;
    background-color: #1976f2;
    border: 0;
  }

  &-go {
    color: #808080;
    background-color: #fff;
    border: 1px solid #cbcbcb;
  }

  img,
  svg {
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 1rem;
  }
}

.link {
  font-size: 1.4rem;
  cursor: pointer;

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: $dark;
    transition: background 0.35s ease-in-out;
  }

  &:hover::after {
    background-color: transparent;
  }
}

.link-pass {
  font-size: 1.2rem;
  cursor: pointer;

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: #b5b5b5;
    transition: background 0.35s ease-in-out;
  }

  &:hover::after {
    background-color: transparent;
  }
}

.is-invalid {
  color: #ea5151;
}

.backdrop {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: transparent;
}

@keyframes loading {
  0% {
    left: -25%;
  }

  90%,
  100% {
    left: 100%;
  }
}
</style>
