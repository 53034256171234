import { useVfm } from 'vue-final-modal'
import type { RewardModalProps } from '~/types/reward'

export const useManagePostLogin =
  (emit?: (evt: 'openRewardModal', data: RewardModalProps) => void) =>
  async () => {
    const notificationsStore = useNotificationsStore()

    const cookieLogin = useCookie('connected', {
      secure: import.meta.env.NODE_ENV === 'production',
      sameSite: 'lax',
      path: '/',
    })
    cookieLogin.value = 'true'

    await notificationsStore.fetchUnreadCount()

    const { gtag } = useGtag()
    gtag('event', 'uaconnect', {
      memberid: '',
    })

    const auth = useAuth()

    const rawUser = unref(auth.data)

    if (rawUser?.badges_reward) {
      const rewardData = {
        title: `Bienvenue ${rawUser.firstname}`,
        messages: [
          'Votre inscription est à présent validée et vous rapporte des points pour le mois en cours !',
        ],
        badges: rawUser.badges_reward,
        points: rawUser.points_total,
        ranking: rawUser.ranking,
        actions: [
          {
            type: 'route' as const,
            route: '/mon-profil',
            label: 'Voir mon profil',
            outline: true,
          },
          {
            type: 'route' as const,
            route: '/avantages',
            label: 'Découvrir mes avantages',
          },
        ],
      }

      return emit?.('openRewardModal', rewardData)
    }

    const route = useRoute()

    const vfm = useVfm()

    if (Object.hasOwn(route.query, 'upm')) {
      vfm.closeAll()

      return navigateTo({ path: '/mon-profil?upm' })
    }

    return vfm.closeAll()
  }
